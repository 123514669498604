export const SidebarDataCrc = [
  {
    title: 'Dashboard',
    path: '/',
    cName: 'nav-text'
  },
  {
    title: 'Alumni Directory',
    path: '/alumni_directory',
    cName: 'nav-text'
  }
  ,
  {
    title: 'Career Opportunity',
    path: '/career_opportunity',
    cName: 'nav-text'
  },
  {
    title: 'Further Education',
    path: '/further_education',
    cName: 'nav-text'
  },
  {
    title: 'Support & Giving',
    path: '#',
    cName: 'nav-text',
    subNav: [
      {
        title: 'Donation Options',
        path: '/donation_options',
        cName: 'nav-sub-text'
      },
      {
        title: 'Mentoring Programs',
        path: '/mentoring_programs',
        cName: 'nav-sub-text'
      },
    ]
  },
  {
    title: 'Social & Networking',
    path: '#',
    cName: 'nav-text',
    subNav: [
      {
        title: 'Reunion Events',
        path: '/events',
        cName: 'nav-sub-text'
      },
      {
        title: 'Discussion Forums',
        path: '/discussion_forums',
        cName: 'nav-sub-text'
      }
    ]
  },
  {
    title: 'Contact & Posting',
    path: '#',
    cName: 'nav-text',
    subNav: [
      {
        title: 'Frequent Inquiries',
        path: '/frequent_inquiries',
        cName: 'nav-sub-text'
      },
      {
        title: 'Alumni Story Posts',
        path: '/alumni_story_posts',
        cName: 'nav-sub-text'
      },
      {
        title: 'Alumni Business Posts',
        path: '/alumni_business_posts',
        cName: 'nav-sub-text'
      },
      {
        title: 'News Posts',
        path: '/news_posts',
        cName: 'nav-sub-text'
      },
      {
        title: 'Add Grade',
        path: '/add_grade',
        cName: 'nav-sub-text'
      },
      {
        title: 'Add Combination',
        path: '/add_combination',
        cName: 'nav-sub-text'
      },
      {
        title: 'Add EPs',
        path: '/add_eps',
        cName: 'nav-sub-text'
      },
      {
        title: 'Add Alumni',
        path: '/add_alumni',
        cName: 'nav-sub-text'
      }
    ]
  },
];